<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-3">
        {{ inputs.language === 'en' ? 'Experiment 5: Question 6' : 'Expérience 5: Question 6' }}
      </h2>

      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'During this experiment, it is very important that we DO NOT use acetone because:'
            : 'Lors de cette expérience, il est important de NE PAS utiliser l’acétone car :'
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab5Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'It is very flammable and can cause serious damage to the lab',
          value: 'veryFlammable',
        },
        {
          text: 'It is very toxic and exposure to it can result in serious damage to health',
          value: 'veryToxic',
        },
        {
          text: 'It is very likely to cause side reactions hence ethanol will be used instead',
          value: 'sideReactions',
        },
        {
          text: 'It is very expensive and too much is used in the lab.',
          value: 'expensive',
        },
      ],
      optionsFr: [
        {
          text: 'Il est très inflammable et peut endommager le laboratoire',
          value: 'veryFlammable',
        },
        {
          text: 'Il est très toxique et l’exposition à l’acétone peut entraîner des conséquences négatives à la santé',
          value: 'veryToxic',
        },
        {
          text: "Il est très probable que l’usage de l’acétone produira d’autres réactions donc de l'éthanol sera utilisé à sa place",
          value: 'sideReactions',
        },
        {
          text: 'Il est très dispendieux et trop d’acétone est utilisé au laboratoire',
          value: 'expensive',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
